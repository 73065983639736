import React from "react"
import { RichText } from "../common/rich-text.component"
import { Section } from "../common/section.component"
import { Collection } from "../common/collection.component"

export const Features = ({ slice }) => {

  const { primary, fields, label } = slice
  const backgroundColor = primary && primary.bg_color
  const backgroundImage = primary && primary.bg_image
  console.log("slice ", slice);
  console.log("label", label);
  return (
    <Section className={`${label != "col-12-service-info" && label != "col-12" ? "py-5" : ""}`} backgroundImage={backgroundImage} backgroundColor={backgroundColor}>
      <div className={`features-slice ${label ? "features-slice-" + label : ""}`}>
        <div className="container feature-container-header text-center mb-5">
          <RichText render={primary.title}/>
          <RichText render={primary.text}/>
        </div>
        <Collection items={fields} type={label} />
      </div>
    </Section>
  )
}



