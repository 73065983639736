import React from 'react';
import { graphql, StaticQuery } from "gatsby"
import { withPreview } from "gatsby-source-prismic-graphql"
import { Image } from "./common/image.component"
import { LinksRow } from "./common/links-row.component"
import { RichText } from "prismic-reactjs";
import { Slices } from "./slices.component"
import { linkFragment } from "../link-resolver"

const query = graphql`
    query footerQuery {
        prismic {
            allFooters {
                edges {
                    node {
                        bg_color
                        copyright
                        body {
                            ... on PRISMIC_FooterBodyCustom_script {
                                type
                                label
                                fields {
                                    script_html
                                    script_url
                                }
                            }
                            ... on PRISMIC_FooterBodyMenu {
                                type
                                label
                                fields {
                                    icon
                                    link {
                                        ...link
                                    }
                                    link_style
                                    link_text
                                }
                            }
                        }
                        logo
                        text
                        _meta {
                            uid
                            type
                            lang
                        }
                    }
                }
            }
        }
    }
`;

export const Footer = ({lang = 'en-us'}) => {
  return (
    <StaticQuery query={query} render={withPreview(data => {
      const footer = data.prismic.allFooters.edges.find(item => item.node._meta.lang === lang);
      if(footer) {
          return (
            <footer style={{backgroundColor: footer.node.bg_color}} className="py-6 px-2 footer-cover">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-lg-2 col-md-3 col-xl-4 mb-2 mb-lg-0">
                            <div className="navbar-logo mx-auto mx-md-0 mt-md-3 mt-lg-0">
                                <Image alt="logo" image={footer.node.logo}/>
                            </div>
                        </div>
                        <div className="col-12 col-lg-10 col-md-9 col-xl-8 text-md-right text-center">
                            {
                                footer.node.body.map(slice => {
                                    switch(slice.type) {
                                        case 'menu':
                                            return <LinksRow
                                            links={slice.fields}
                                            className="font-medium list-unstyled d-flex flex-wrap justify-content-md-end" />
                                        default:
                                            return null;
                                    }
                                })
                            }

                        </div>
                    </div>
                    <div className="text-left">
                        <RichText render={footer.node.copyright} />
                    </div>
                </div>
                <Slices body={footer.node.body}/>
            </footer>
          )
      }
      console.log("");
      return null;
    }, query, [linkFragment])} />
   );
}



